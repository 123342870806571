import * as React from "react";
import { PositionContextElement, LayoutPositionElement } from "@expediagroup/mojo-protocol-typescript-client";
import { mjRenderChildren } from "../MJRenderChildren";
import {
  UitkLayoutPositionProps,
  UitkLayoutPositionValue,
  UitkLayoutPositionTypes,
  UitkLayoutPositionPointerEvents,
} from "uitk-react-layout-position";
import { UitkLayoutTag, UitkLayoutTags } from "uitk-react-layout";
import { classNames } from "uitk-react-utils";

export const MJPositionContext: React.FC<{
  element: PositionContextElement;
}> = ({ element }) => {
  if (element.children[0] && element.children[0].name === "LayoutPositionElement") {
    element.children[0].name = "LayoutPositionFirstChildElement";
  }

  return (
    <CustomLayoutPosition type="relative" style={{ height: "100%", width: "100%" }}>
      {mjRenderChildren(element.children)}
    </CustomLayoutPosition>
  );
};

const toOffset = (uitkAlignment: string | undefined, mojoAlignment: string | undefined, offset = 0) => {
  if (uitkAlignment === "start" && mojoAlignment === "center") return "center";
  return uitkAlignment === mojoAlignment ? offset : undefined;
};

export const MJLayoutPositionFirstChild: React.FC<{ element: LayoutPositionElement }> = ({ element }) => {
  return <MJLayoutPosition element={element} isFirstChild />;
};
export const MJLayoutPosition: React.FC<{ element: LayoutPositionElement; isFirstChild?: boolean }> = ({
  element,
  isFirstChild,
}) => {
  const { blockAxisAlignment, inlineAxisAlignment, blockAxisOffset, inlineAxisOffset } = element;
  return (
    <CustomLayoutPosition
      type={isFirstChild ? "relative" : "absolute"}
      position={{
        top: toOffset("start", blockAxisAlignment, blockAxisOffset) as UitkLayoutPositionValue,
        bottom: toOffset("end", blockAxisAlignment, blockAxisOffset) as UitkLayoutPositionValue,
        left: toOffset("start", inlineAxisAlignment, inlineAxisOffset) as UitkLayoutPositionValue,
        right: toOffset("end", inlineAxisAlignment, inlineAxisOffset) as UitkLayoutPositionValue,
      }}
      style={
        element.children[0] && element.children[0].name === "ImageElement" ? { height: "100%", width: "100%" } : {}
      }
      cloneElement
    >
      <div>{mjRenderChildren(element.children)}</div>
    </CustomLayoutPosition>
  );
};

const CustomLayoutPosition: React.FC<
  React.PropsWithChildren<UitkLayoutPositionProps & React.HTMLAttributes<HTMLElement>>
> = (props) => {
  const {
    children,
    className,
    cloneElement,
    display,
    pointerEvents,
    position,
    style,
    tag,
    type,
    ...attributes
  } = props;

  const childrenArray = React.Children.toArray(children);
  const firstChild: React.ReactChild | React.ReactFragment | React.ReactPortal = childrenArray[0];

  if (!firstChild || React.isValidElement(firstChild) === false) {
    return null;
  }

  const firstChildClassName = firstChild.props.className;
  const firstChildStyle = firstChild.props.style;

  const baseClass = "uitk-layout-position";

  const wrapperClassNames = classNames.bind(baseClass)(
    baseClass,
    cloneElement && firstChildClassName && firstChildClassName,
    display && `${baseClass}-display-${display}`,
    pointerEvents && `${baseClass}-${UitkLayoutPositionPointerEvents.get(pointerEvents)}`,
    position &&
      Object.entries(position)
        .filter(([, val]) => typeof val === "string")
        .map(([key, val]) => `${baseClass}-${key}-${val}`)
        .join(" "),
    type && `${baseClass}-${UitkLayoutPositionTypes.get(type)}`,
    `${baseClass}-custom`,
    className
  );

  const wrapperStyle = {
    ...firstChildStyle,
    ...style,
  } as React.CSSProperties;

  if (position) {
    Object.entries(position).forEach(([key, val]) => {
      if (typeof val === "number") {
        (wrapperStyle as any)[`--uitk-custom-${key}-position`] = `${val}px`;
      }
    });
  }

  const decoratedChild = React.cloneElement(firstChild, {
    className: wrapperClassNames,
    ...(Object.keys(wrapperStyle).length && {
      style: wrapperStyle,
    }),
    ...attributes,
  } as any);

  const Tag = ((tag && UitkLayoutTags.get(tag)) || UitkLayoutTags.get("div")) as UitkLayoutTag;

  return cloneElement ? (
    decoratedChild
  ) : (
    <Tag className={wrapperClassNames} {...attributes} style={wrapperStyle}>
      {" "}
      {children || null}{" "}
    </Tag>
  );
};
