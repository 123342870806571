import * as React from "react";
import { StackElement } from "@expediagroup/mojo-protocol-typescript-client";
import { mjRenderChildren } from "../MJRenderChildren";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { UitkLayoutFlexDirection, UitkLayoutFlexJustifyContent } from "uitk-react-layout-flex/dist/types";

const getDirection = (dir: string | undefined) => {
  switch (dir) {
    case "horiz":
      return "row";
    case "vert":
      return "column";
    default:
      return "column";
  }
};

const getAlignment = (alignment: string | undefined) => {
  switch (alignment) {
    case "leading":
      return "start";
    case "center":
      return "center";
    case "trailing":
      return "end";
    default:
      return undefined;
  }
};

export const MJStack: React.FC<{
  element: StackElement;
}> = ({ element }) => {
  const props = {
    direction: getDirection(element.dir) as UitkLayoutFlexDirection,
    alignContent: getAlignment(element.alignment) as UitkLayoutFlexJustifyContent,
  };

  return (
    <UitkLayoutFlex {...props} style={{ gap: `${element.spacing}` }} data-testid="mojo-stack">
      {mjRenderChildren(element.children).map((child, i) => (
        <UitkLayoutFlexItem key={`stack-item_${i}`} alignSelf={getAlignment(element.alignment)}>
          <div>{child}</div>
        </UitkLayoutFlexItem>
      ))}
    </UitkLayoutFlex>
  );
};

export default MJStack;
