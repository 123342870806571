import * as React from "react";
import {
  Element,
  CarouselElement,
  CardElement,
  CardContentSectionElement,
  ImageElement,
  TextElement,
  TextNodeElement,
  IconElement,
  SpacingElement,
  StackElement,
  BadgeElement,
  LayoutGridElement,
  LayoutGridItemElement,
  LinkElement,
  ButtonElement,
  HeadingElement,
  HeadingScopeElement,
  ImpressionElement,
  PositionContextElement,
  LayoutPositionElement,
} from "@expediagroup/mojo-protocol-typescript-client";
import MJCarousel from "./adapters/MJCarousel";
import MJImage from "./adapters/MJImage";
import MJCard from "./adapters/MJCard";
import MJText from "./adapters/MJText";
import MJTextNode from "./adapters/MJTextNode";
import MJIcon from "./adapters/MJIcon";
import MJSpacing from "./adapters/MJSpacing";
import MJStack from "./adapters/MJStack";
import MJBadge from "./adapters/MJBadge";
import MJCardContentSectionElement from "./adapters/MJCardContentSection";
import { MJLayoutGrid, MJLayoutGridItem } from "./adapters/MJLayoutGrid";
import { MJLink } from "./adapters/MJLink";
import { MJButton } from "./adapters/MJButton";
import { MJHeading, MJHeadingScope } from "./adapters/MJHeading";
import { MJImpression } from "./adapters/MJImpression";
import { MJPositionContext, MJLayoutPosition, MJLayoutPositionFirstChild } from "./adapters/MJPositionContext";

export enum ElementType {
  Carousel = "CarouselElement",
  Card = "CardElement",
  CardContentSection = "CardContentSectionElement",
  Image = "ImageElement",
  Text = "TextElement",
  TextNode = "TextNodeElement",
  Icon = "IconElement",
  Spacing = "SpacingElement",
  Stack = "StackElement",
  Badge = "BadgeElement",
  LayoutGrid = "LayoutGridElement",
  LayoutGridItem = "LayoutGridItemElement",
  Link = "LinkElement",
  Button = "ButtonElement",
  Heading = "HeadingElement",
  HeadingScope = "HeadingScopeElement",
  Impression = "ImpressionElement",
  PositionContext = "PositionContextElement",
  LayoutPositionFirstChild = "LayoutPositionFirstChildElement",
  LayoutPosition = "LayoutPositionElement",
}

export const mjRenderChildren = (elements: Element[]) => {
  const children = elements.map((element, i) => {
    const key = `${element.name}-${i}`;

    switch (element.name) {
      case ElementType.Carousel:
        return <MJCarousel element={element as CarouselElement} key={key} />;

      case ElementType.Card:
        return <MJCard element={element as CardElement} key={key} />;

      case ElementType.CardContentSection:
        return <MJCardContentSectionElement element={element as CardContentSectionElement} key={key} />;

      case ElementType.Image:
        return <MJImage element={element as ImageElement} key={key} />;

      case ElementType.Text:
        return <MJText element={element as TextElement} key={key} />;

      case ElementType.TextNode:
        return <MJTextNode element={element as TextNodeElement} key={key} />;

      case ElementType.Icon:
        return <MJIcon element={element as IconElement} key={key} />;

      case ElementType.Spacing:
        return <MJSpacing element={element as SpacingElement} key={key} />;

      case ElementType.Stack:
        return <MJStack element={element as StackElement} key={key} />;

      case ElementType.Badge:
        return <MJBadge element={element as BadgeElement} key={key} />;

      case ElementType.LayoutGrid:
        return <MJLayoutGrid element={element as LayoutGridElement} key={key} />;

      case ElementType.LayoutGridItem:
        return <MJLayoutGridItem element={element as LayoutGridItemElement} key={key} />;

      case ElementType.Link:
        return <MJLink element={element as LinkElement} key={key} />;

      case ElementType.Button:
        return <MJButton element={element as ButtonElement} key={key} />;

      case ElementType.Heading:
        return <MJHeading element={element as HeadingElement} key={key} />;

      case ElementType.HeadingScope:
        return <MJHeadingScope element={element as HeadingScopeElement} key={key} />;

      case ElementType.Impression:
        return <MJImpression element={element as ImpressionElement} key={key} />;

      case ElementType.PositionContext:
        return <MJPositionContext element={element as PositionContextElement} key={key} />;

      case ElementType.LayoutPositionFirstChild:
        return <MJLayoutPositionFirstChild element={element as LayoutPositionElement} key={key} />;

      case ElementType.LayoutPosition:
        return <MJLayoutPosition element={element as LayoutPositionElement} key={key} />;

      default:
        return null;
    }
  });
  return children;
};
