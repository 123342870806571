import * as React from "react";
import { UitkCarousel } from "uitk-react-carousel";
import { CarouselElement } from "@expediagroup/mojo-protocol-typescript-client";
import { mjRenderChildren } from "../MJRenderChildren";
import { UitkLayoutGrid } from "uitk-react-layout-grid";

export const MJCarousel: React.FC<{
  element: CarouselElement;
}> = ({ element }) => {
  const props = {
    itemsMaxHeight: true,
    itemsVisible: {
      sm: element.visibleItemCount?.small || 2,
      md: element.visibleItemCount?.medium || 3,
      lg: element.visibleItemCount?.large || 4,
      xl: element.visibleItemCount?.extra_large || 5,
    },
    pageBy: 1,
    peek: element.peek === true,
    wrapItems: true,
    loop: element.loopItems,
    pagingInset: element.pagingButtonsInset,
    buttonText: {
      nextButton: element.nextButtonLabel || "Show next card",
      prevButton: element.previousButtonLabel || "Show previous card",
    },
  };

  return (
    <UitkCarousel {...props}>
      {mjRenderChildren(element.children).map((child, i) => (
        <UitkLayoutGrid blockSize="full_size" key={`Carousel-Mojo-${i}`}>
          {child}
        </UitkLayoutGrid>
      ))}
    </UitkCarousel>
  );
};

export default MJCarousel;
