import * as React from "react";
import { TextElement } from "@expediagroup/mojo-protocol-typescript-client";
import { mjRenderChildren } from "../MJRenderChildren";

interface WeightMap {
  bold: number;
  medium: number;
  regular: number;
  light: number;
}

interface TextDecorationMap {
  lineThrough: string;
  underline: string;
}

const weightMap: WeightMap = {
  bold: 700,
  medium: 500,
  regular: 400,
  light: 300,
};

const textDecorationMap: TextDecorationMap = {
  lineThrough: "line-through",
  underline: "underline",
};

const I: React.FC = ({ children }) => <i>{children}</i>;
const InlineTextContext = React.createContext<boolean>(false);

export const MJText: React.FC<{
  element: TextElement;
}> = ({ element }) => {
  const isInline = React.useContext(InlineTextContext) || element.inline;
  const ItalicWrapper = element.decoration === "italic" ? I : React.Fragment;

  const overflowStyles: object = element.maxLines
    ? {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: element.maxLines,
        whiteSpace: "normal",
        WebkitBoxOrient: "vertical",
      }
    : {};

  const props = {
    style: {
      fontSize: element.size,
      fontWeight: weightMap[element.weight as keyof WeightMap],
      color: element.color,
      textAlign: element.align as any,
      textDecoration: textDecorationMap[(element.decoration as keyof TextDecorationMap) ?? ""],
      ...overflowStyles,
    },
  };

  if (isInline) {
    return (
      <InlineTextContext.Provider value={true}>
        <span {...props} data-testid="mojo-text">
          <ItalicWrapper>{mjRenderChildren(element.children)}</ItalicWrapper>
        </span>
      </InlineTextContext.Provider>
    );
  }

  return (
    <InlineTextContext.Provider value={true}>
      <div {...props} data-testid="mojo-text">
        <ItalicWrapper>{mjRenderChildren(element.children)}</ItalicWrapper>
      </div>
    </InlineTextContext.Provider>
  );
};

export default MJText;
