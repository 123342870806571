import * as React from "react";
import { LinkElement } from "@expediagroup/mojo-protocol-typescript-client";
import { UitkLink, UitkLinkSize } from "uitk-react-link";
import { mjRenderChildren } from "../MJRenderChildren";
import { useMojoAction } from "../actions/execute";

export const MJLink: React.FC<{ element: LinkElement }> = ({ element }) => {
  const { execute, link } = useMojoAction(element.action);

  return (
    <UitkLink size={element.size as UitkLinkSize} disabled={element.disabled}>
      <a href={link?.href} onClickCapture={execute}>
        {mjRenderChildren(element.children)}
      </a>
    </UitkLink>
  );
};

export default MJLink;
