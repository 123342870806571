import * as React from "react";
import { ImpressionElement } from "@expediagroup/mojo-protocol-typescript-client";
import { IntersectionTracker } from "@shared-ui/clickstream-analytics-context";
import { useMojoAction } from "../actions/execute";
import { mjRenderChildren } from "../MJRenderChildren";

export const MJImpression: React.FC<{ element: ImpressionElement }> = ({ element }) => {
  const { uisPrime } = useMojoAction(element.action);

  if (!uisPrime) {
    return <>{mjRenderChildren(element.children)}</>;
  }

  return (
    <IntersectionTracker
      intersectionOptions={{ threshold: 1.0 }}
      linkName={uisPrime.linkName}
      referrerId={uisPrime.referrerId}
    >
      <div style={{ height: "100%" }}>{mjRenderChildren(element.children)}</div>
    </IntersectionTracker>
  );
};

export default MJImpression;
