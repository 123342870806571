import { UisPrimeTrackingAction } from "@expediagroup/mojo-protocol-typescript-client";
import { ActionContext, ActionExecutor, MOJO_ACTION_EXECUTOR_ERROR } from "./types";
import { executeAction } from "./execute";

export const uisPrimeTrackingAction: ActionExecutor = (action: UisPrimeTrackingAction, context: ActionContext) => {
  try {
    context.uisPrimeClickTracker(action.referrerId || "mojo.unknown", action.linkName || "Mojo Unknown", false);
    action.onSuccess?.forEach((a) => {
      executeAction(a, context);
    });
  } catch (error) {
    context.logger.event?.(MOJO_ACTION_EXECUTOR_ERROR, { action_name: action.name, error });
    action.onFailure?.forEach((a) => {
      executeAction(a, context);
    });
  }
};
