import * as React from "react";
import { LayoutGridElement, LayoutGridItemElement } from "@expediagroup/mojo-protocol-typescript-client";
import { UitkLayoutGrid, UitkLayoutGridItem, UitkLayoutGridItemProps } from "uitk-react-layout-grid";
import { mjRenderChildren } from "../MJRenderChildren";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";

export const MJLayoutGrid: React.FC<{ element: LayoutGridElement }> = ({ element }) => {
  const columns = element.columns
    ? Object.keys(element.columns).reduce((acc: any, k) => {
        acc[k] = element.columns?.[k].count;
        return acc;
      }, {})
    : undefined;

  return (
    <UitkLayoutGrid style={{ gap: `${element["spacing"]}` }} columns={columns}>
      {mjRenderChildren(element.children)}
    </UitkLayoutGrid>
  );
};

export const MJLayoutGridItem: React.FC<{ element: LayoutGridItemElement }> = ({ element }) => {
  const colSpan = element.colSpan
    ? Object.keys(element.colSpan).reduce((acc: any, k) => {
        acc[k] = element.colSpan?.[k].count;
        return acc;
      }, {})
    : undefined;

  const alignSelf = element.align as UitkLayoutGridItemProps["alignSelf"];
  const justifySelf = element.justify as UitkLayoutGridItemProps["justifySelf"];

  if (!colSpan) {
    return (
      <UitkLayoutGridItem colSpan={colSpan} alignSelf={alignSelf} justifySelf={justifySelf}>
        <div>{mjRenderChildren(element.children)}</div>
      </UitkLayoutGridItem>
    );
  }

  return (
    <Viewport>
      <ViewSmall>
        {colSpan["small"] === 0 ? (
          <></>
        ) : (
          <UitkLayoutGridItem colSpan={colSpan} alignSelf={alignSelf} justifySelf={justifySelf}>
            <div>{mjRenderChildren(element.children)}</div>
          </UitkLayoutGridItem>
        )}
      </ViewSmall>
      <ViewMedium>
        {colSpan["medium"] === 0 ? (
          <></>
        ) : (
          <UitkLayoutGridItem colSpan={colSpan} alignSelf={alignSelf} justifySelf={justifySelf}>
            <div>{mjRenderChildren(element.children)}</div>
          </UitkLayoutGridItem>
        )}
      </ViewMedium>
      <ViewLarge>
        {colSpan["large"] === 0 ? (
          <></>
        ) : (
          <UitkLayoutGridItem colSpan={colSpan} alignSelf={alignSelf} justifySelf={justifySelf}>
            <div>{mjRenderChildren(element.children)}</div>
          </UitkLayoutGridItem>
        )}
      </ViewLarge>
    </Viewport>
  );
};
