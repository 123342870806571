import * as React from "react";
import { SpacingElement } from "@expediagroup/mojo-protocol-typescript-client";
import { mjRenderChildren } from "../MJRenderChildren";

export const MJSpacing: React.FC<{
  element: SpacingElement;
}> = ({ element }) => {
  return (
    <div
      style={{
        paddingInlineStart: element.padding?.["inlinestart"],
        paddingInlineEnd: element.padding?.["inlineend"],
        paddingBlockEnd: element.padding?.["blockend"],
        paddingBlockStart: element.padding?.["blockstart"],
        marginInlineStart: element.margin?.["inlinestart"],
        marginInlineEnd: element.margin?.["inlineend"],
        marginBlockStart: element.margin?.["blockstart"],
        marginBlockEnd: element.margin?.["blockend"],
      }}
      data-testid="mojo-spacing"
    >
      {mjRenderChildren(element.children)}
    </div>
  );
};

export default MJSpacing;
