import { NavigateAction } from "@expediagroup/mojo-protocol-typescript-client";
import { ActionContext, ActionExecutor, MOJO_ACTION_EXECUTOR_ERROR } from "./types";
import { executeAction } from "./execute";

/**
 * The NavigateAction is handled by providing native browser navigation to necessary MojoElements.
 */
export const navigateAction: ActionExecutor = (action: NavigateAction, context: ActionContext): void => {
  try {
    action.onSuccess?.forEach((a) => {
      executeAction(a, context);
    });
  } catch (error) {
    context.logger.event?.(MOJO_ACTION_EXECUTOR_ERROR, { action_name: action.name, error });
    action.onFailure?.forEach((a) => {
      executeAction(a, context);
    });
  }
};
