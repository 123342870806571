import * as React from "react";
import { CardElement } from "@expediagroup/mojo-protocol-typescript-client";
import { UitkCard, UitkCardLink, UitkCardFeaturedHeader } from "uitk-react-cards";
import { mjRenderChildren } from "../MJRenderChildren";
import { useMojoAction } from "../actions/execute";

export const MJCard: React.FC<{
  element: CardElement;
}> = ({ element }) => {
  const { execute, link } = useMojoAction(element.action);

  const props = {
    border: element.border,
    selected: element.selected,
    padded: element.padded,
  };

  const headerText = (element.headerContent?.find(({ name }) => name === "TextNodeElement") as any)?.text;

  return (
    <UitkCard {...props} data-testid="mojo-card">
      <div style={{ height: "100%", background: element.backgroundColor }}>
        {headerText && <UitkCardFeaturedHeader title={{ text: headerText }} />}
        {mjRenderChildren(element.children)}
        {link?.href && (
          <UitkCardLink>
            <a href={link.href} onClickCapture={execute} style={{ zIndex: "unset" }}>
              {link.accessibilityText}
            </a>
          </UitkCardLink>
        )}
      </div>
    </UitkCard>
  );
};

export default MJCard;
