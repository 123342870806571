import { Action, NavigateAction, UisPrimeTrackingAction } from "@expediagroup/mojo-protocol-typescript-client";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { useLogger } from "@shared-ui/logger-context";
import { uisPrimeTrackingAction } from "./UisPrimeTrackingAction";
import { ActionContext } from "./types";
import { navigateAction } from "./NavigateAction";

function flatten(actions: Action[]) {
  return actions.reduce<Action[]>((acc, action) => {
    acc.push({ ...action, onSuccess: [], onFailure: [] });
    if (action.onSuccess?.length) {
      acc = acc.concat(flatten(action.onSuccess));
    }

    return acc;
  }, []);
}

export function useMojoAction(action?: Action) {
  const track = useClickTracker();
  const logger = useLogger();

  if (!action) {
    return {
      execute: () => {},
      link: null,
      uisPrime: null,
    };
  }

  const flattenActions = flatten([action]);

  // possibly extract a link from the first NavigateAction to provide browser native navigation.
  const navigateAction: NavigateAction | undefined = flattenActions.find((a) => a.name === "NavigateAction");
  const link = navigateAction?.webTarget?.url
    ? { href: navigateAction.webTarget.url, accessibilityText: navigateAction.accessibilityText }
    : null;

  // possibly extract the first referrerId and linkName from a UisPrimeTrackingAction to make impression tracking easier
  const uisPrimeAction: UisPrimeTrackingAction | undefined = flattenActions.find(
    (a) => a.name === "UisPrimeTrackingAction"
  );
  const uisPrime =
    uisPrimeAction?.referrerId && uisPrimeAction?.linkName
      ? { referrerId: uisPrimeAction.referrerId, linkName: uisPrimeAction.linkName }
      : null;

  return {
    execute: () => {
      executeAction(action, { uisPrimeClickTracker: track, logger });
    },
    link,
    uisPrime,
  };
}

export function executeAction(action: Action, context: ActionContext) {
  switch (action.name) {
    case "UisPrimeTrackingAction":
      uisPrimeTrackingAction(action, context);
      break;
    case "NavigateAction":
      navigateAction(action, context);
      break;
  }
}
