import { Action } from "@expediagroup/mojo-protocol-typescript-client";
import { ILogger, SystemEvent, SystemEventLevel } from "@shared-ui/logger-context";

export const MOJO_ACTION_EXECUTOR_ERROR = new SystemEvent(SystemEventLevel.WARN, "MOJO_ACTION_EXECUTOR_ERROR");

export interface ActionContext {
  uisPrimeClickTracker: (rfrr: string, linkName: string, delay?: boolean) => void;
  logger: ILogger;
}

export type ActionExecutor = (action: Action, context: ActionContext) => void;
