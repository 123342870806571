import * as React from "react";
import { TextNodeElement } from "@expediagroup/mojo-protocol-typescript-client";

export const MJTextNode: React.FC<{
  element: TextNodeElement;
}> = ({ element }) => {
  return <>{element.text}</>;
};

export default MJTextNode;
