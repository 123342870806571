import * as React from "react";
import { MojoQueryDocument } from "__generated__/typedefs";
import { useBexApi } from "@shared-ui/bex-api-context";
import { observer } from "mobx-react";
import { withStores } from "src/stores";
import { MojoProps } from "./typings";
import { useQuery } from "@apollo/client";
import { ElementFromJSON, Element } from "@expediagroup/mojo-protocol-typescript-client";
import { mjRenderChildren } from "./util/MJRenderChildren";
import { useMojoData } from "src/components/utility/Mojo/MojoDataContext";

export const Mojo = withStores("context")(
  observer((props: MojoProps) => {
    const { templateComponent, context } = props;

    const mojoContext = {
      pageName: context.searchContext.pageType.toString(),
      placementId: templateComponent.config.placementId,
    };

    const variables = {
      context: useBexApi().context,
      mojoContext,
    };

    const { element: mockedElement, shouldSkipQuery } = useMojoData();

    const { data, error, loading } = useQuery(MojoQueryDocument, {
      variables,
      skip: shouldSkipQuery,
    });

    if (shouldSkipQuery) {
      return <div data-testid="mojo-mocked">{mockedElement && mjRenderChildren([ElementFromJSON(mockedElement)])}</div>;
    }

    if (error || loading || !data || !data.mojoSection?.data) {
      return null;
    }

    const json = JSON.parse(data.mojoSection?.data);
    const element: Element = ElementFromJSON(json);

    return <div data-testid="mojo">{mjRenderChildren([element])}</div>;
  })
);

export default Mojo;
